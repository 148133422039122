import React, { FC, useState } from 'react'
import { ChevronBottom } from '../icons/chevron-bottom'
import { CheveronRight } from '../icons/cheveron-right'
import style from './tabs.module.scss'

/**
 *
 */
export interface TabsProps {
  header: string[]
  changeHeader: (val: string) => void
  children: any
  selected: string
  variant: 'DEFAULT' | 'LEAN' | 'LIST'
  type: 'VERTICAL' | 'HORIZONTAL'
  className?: any
  pimStyle?: any
  overflow?: any
  headertitle?: any
  removeAlignment?: boolean
  tabs?: any
}

/**
 * Tabs
 * @param {TabsProps} props TabsProps
 * @returns {JSX.Element}
 */
export const EmiTabs: FC<TabsProps> = (props): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false)
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  const classMap = {
    '': '',
    DEFAULT: 'tabs__header--default',
    LEAN: 'tabs__header--lean',
    LIST: 'tabs__header--list',
  }
  const verticalHorizontalMap = {
    VERTICAL: 'tabs__header__vertical',
    HORIZONTAL: 'tabs__header__horizontal',
  }
  const activeHeaderClassMap = {
    '': 'tabs__header__item--active',
    DEFAULT: 'tabs__header__item--active',
    LEAN: 'tabs__header--lean__item--active',
    LIST: 'tabs__header--list__item--active',
  }
  return (
    <div
      className={`${style.tabs__header}  ${
        style[verticalHorizontalMap[props.type]]
      }  ${props.overflow == true ? style.mobile_vertical_head : ''}`}
    >
      <div
        className={`${style.tabs__header__horizontal__flex} ${
          style[classMap[props.variant]]
        } ${props.type == 'VERTICAL' && style.vertical_default} ${
          props?.headertitle != undefined
            ? props?.headertitle?.mode[props?.headertitle?.deviceMode].header
                ?.style.textAlign == 'left'
              ? style.tab_align_left
              : style.tab_align_right
            : ''
        }`}
      >
        {props.type == 'VERTICAL' ? (
          <div className={`${style.display_button}`}>
            <button
              className=" justify-between w-full p-2 border-gray-300 border"
              onClick={() => toggleDropdown()}
              style={{ display: 'flex' }}
            >
              <span className="text-sm">{props.selected}</span>
              <span className={`${showDropdown ? '' : 'transform rotate-90'}`}>
                <CheveronRight />
              </span>{' '}
            </button>{' '}
          </div>
        ) : null}
        {!!props?.headertitle &&
          props?.headertitle?.mode[props?.headertitle?.deviceMode].header?.style
            ?.textAlign != 'center' && (
            <div>
              {props?.headertitle?.mode[props?.headertitle?.deviceMode]
                .isHeader == true && (
                <p
                  className={`mt-3 mb-3 pb-1 pl-3 w-max`}
                  style={{
                    color:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.fontColor,
                    fontSize:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.fontSize,
                    fontWeight:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.fontStyle?.isBold && 'bold',
                    fontStyle:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.fontStyle?.isItalic && 'italic',
                    textDecoration:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.fontStyle?.isUnderLine && 'underline',
                    justifyContent:
                      props?.headertitle?.mode[props?.headertitle?.deviceMode]
                        .header?.style?.textAlign,
                  }}
                >
                  {
                    props?.headertitle?.mode[props?.headertitle?.deviceMode]
                      .header?.title
                  }
                </p>
              )}
            </div>
          )}
        <div
          className={`text-sm ${
            props.type == 'VERTICAL'
              ? `w-full ${
                  props.overflow == true ? 'h-auto' : 'h-96 overflow-y-scroll'
                }`
              : `flex sm:w-full md:h-auto ${
                  props.overflow == true &&
                  !props.removeAlignment &&
                  'justify-center'
                } ${props.overflow == true ? 'mb-4' : 'overflow-x-scroll'}  ${
                  props?.tabs ? style.tablength_width : style.tab_width
                }`
          } ${showDropdown ? style.showDiv : style.hideDiv} ${
            props?.headertitle != undefined
              ? props?.headertitle?.mode[props.headertitle?.deviceMode].header
                  ?.style?.textAlign != 'center'
                ? props?.headertitle?.mode[props?.headertitle?.deviceMode]
                    .header?.style?.textAlign == 'left'
                  ? style.tabhead_left
                  : style.tabhead_right
                : ''
              : ''
          }`}
        >
          {props.header?.map((ele: any, i: number) => (
            <div
              onClick={() => {
                props.changeHeader(ele), setShowDropdown(!showDropdown)
              }}
              key={i}
              className={`${style.flexy_grids} ${
                props.type == 'VERTICAL' ? '' : ''
              } ${
                props.selected == ele
                  ? style[activeHeaderClassMap[props.variant]]
                  : ''
              }`}
              style={{
                background:
                  props?.pimStyle && props.selected == ele
                    ? props.pimStyle?.background
                    : props.pimStyle?.backgroundColor,
                color:
                  props?.pimStyle && props.selected == ele
                    ? props?.pimStyle?.selectedFontcolor
                    : props?.pimStyle?.fontcolor,
                cursor: props?.pimStyle && props.selected != ele && 'pointer',
                fontSize:
                  props?.headertitle?.mode[props?.headertitle?.deviceMode]
                    .styles?.primary?.headerTextstyle?.fontSize,
                fontWeight:
                  props?.headertitle?.mode[props?.headertitle?.deviceMode]
                    .styles?.primary?.headerTextstyle?.fontStyle?.isBold &&
                  'bold',
                fontStyle:
                  props?.headertitle?.mode[props?.headertitle?.deviceMode]
                    .styles?.primary?.headerTextstyle?.fontStyle?.isItalic &&
                  'italic',
                textDecoration:
                  props?.headertitle?.mode[props?.headertitle?.deviceMode]
                    .styles?.primary?.headerTextstyle?.fontStyle?.isUnderLine &&
                  'underline',
                borderRight: i == props.header.length - 1 && 'none',
              }}
            >
              {/https\:\/\//.test(ele) ? (
                <img
                  src={ele}
                  alt=""
                  className={`${
                    props.overflow == true && style.horizontal_image
                  }`}
                />
              ) : (
                <span className="cursor-pointer whitespace-nowrap">
                  {String(ele).replace(/\_/g, ' ')}
                </span>
              )}
              {props.type == 'VERTICAL' && !/https\:\/\//.test(ele) ? (
                <span className={style.angle_right}>
                  {props.selected == ele ? (
                    <CheveronRight />
                  ) : (
                    <ChevronBottom />
                  )}
                </span>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${style.tabs__content} ${
          props.type == 'VERTICAL' && style.vertical_product
        }`}
      >
        {props?.children?.length >= 1 &&
          props?.children?.map((_ele: any, i: number) => (
            <div
              key={i}
              className={style.tabs__content__tab}
              style={{
                display: props.header[i] === props.selected ? 'block' : 'none',
              }}
            >
              {props.children[i]}
            </div>
          ))}
      </div>
    </div>
  )
}
